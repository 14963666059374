import React, { useState } from "react";
import { Cell, Row } from "@material/react-layout-grid";
import { Body1 } from "@material/react-typography";
import TitledCard from "~/titled-card";
import Button from "~/button";
import { customerManagement, projects, invoices } from "~/routes";
import { useBreadcrumbs } from "~/main-layout/BreadcrumbProvider";
import { Route } from "~/routeTypes";
import { useUser } from "~/Auth/useUser";
import { useRelativeNav } from "~/relative-nav";

import { Customer as CustomerModel, ProjectGroup } from "~/gql/types";
import PageTitleRow from "~/page-title-row";
import "./Customer.scss";
import { AddProjectGroup } from "./AddProjectGroup";
import { ProjectGroupRow } from "./ProjectGroupRow";
import { CustomerBadDebt } from "./CustomerBadDebt";
import { ElectronicInvoicing } from "./ElectronicInvoicing";
import { MenuAuthorizations } from "~/gql/types2";

export const CustomerInfo: React.FC<{ customer: CustomerModel }> = ({
  customer,
}) => {
  const addrLine2 =
    customer.country.length > 0
      ? `${customer.city}, ${customer.country}`
      : `${customer.city}, ${customer.state} ${customer.zip}`;

  return (
    <Cell columns={8}>
      <TitledCard title="Address">
        <Body1>
          {customer.address1} {customer.address2}
          <br />
          {addrLine2}
        </Body1>
      </TitledCard>
      <TitledCard title="Phone">
        <Body1>{customer.phone}</Body1>
      </TitledCard>

      {customer?.fax && customer?.fax != "" ? (
        <TitledCard title="Fax">
          <Body1>{customer.fax}</Body1>
        </TitledCard>
      ) : null}
      <TitledCard title="Notes">
        <Body1>{customer.notes}</Body1>
      </TitledCard>

      <TitledCard title="Taxable in NM">
        <Body1>{`${customer.nmTaxable ? "Yes" : "No"}`}</Body1>
      </TitledCard>

      <TitledCard title="Taxable in TX">
        <Body1>{`${customer.txTaxable ? "Yes" : "No"}`}</Body1>
      </TitledCard>

      {customer.electronicInvoicingSystem && (
        <TitledCard title="Electronic Invoicing System">
          <Body1>{`${customer.electronicInvoicingSystem}`}</Body1>
        </TitledCard>
      )}

      {customer.attn && (
        <TitledCard title="Attn:">
          <Body1>{customer.attn}</Body1>
        </TitledCard>
      )}
    </Cell>
  );
};

export const CustomerProjectGroups: React.FC<{ customer: CustomerModel }> = ({
  customer,
}) => {
  const projectGroups = (customer.projectGroups ?? []) as ProjectGroup[];
  const [open, setOpen] = useState<boolean>(false);
  return (
    <TitledCard
      title="Project Groups"
      actions={
        <AddProjectGroup
          {...{ open, setOpen, customerNumber: customer.number! }}
        />
      }
    >
      {projectGroups.map((g, idx) => (
        <ProjectGroupRow
          key={idx}
          projectGroup={g}
          customerNumber={customer.number!}
        />
      ))}
    </TitledCard>
  );
};

export const Customer: React.FC<{ customer: CustomerModel }> = ({
  customer,
}) => {
  useBreadcrumbs(
    [
      { text: "Customers", to: customerManagement.path },
      { text: `Customer ${customer?.number}: ${customer?.name}` },
    ],
    [customer]
  );

  const nav = useRelativeNav();

  const { authorizations, menus: menuAuths } = useUser();

  const pageActions = (
    <>
      {authorizations.legacyAuth.editCustomer && (
        <Button primary onClick={() => nav("edit")}>
          Edit
        </Button>
      )}
      {menuAuths.has(MenuAuthorizations.Invoices) && (
        <Button
          route={
            new Route(
              `${invoices.path}?customer=${customer.number}&invoiceState=Draft`
            )
          }
        >
          View Draft Invoices
        </Button>
      )}
      <Button route={new Route(projects.path + `?customer=${customer.number}`)}>
        View Projects
      </Button>
    </>
  );

  return (
    <div className="customer-page">
      <PageTitleRow title={customer.name} actions={pageActions} />
      <Row>
        <CustomerInfo customer={customer} />
        <Cell columns={4}>
          {customer.canManageCri && (
            <CustomerProjectGroups customer={customer} />
          )}

          <CustomerBadDebt customer={customer} />
          <ElectronicInvoicing {...{ customer }} />
        </Cell>
      </Row>
    </div>
  );
};

export default Customer;
