export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Decimal: number;
  LocalDate: string;
  UUID: any;
};

export type AddBadDebtWriteOffCommand = {
  amount: Scalars['Decimal'];
  customerNumber: Scalars['String'];
  date: Scalars['LocalDate'];
};

export type ApproveCustomerCommand = {
  createCustomerRequestId: Scalars['String'];
  number: Scalars['String'];
  subNumber: Scalars['String'];
};

export type AuthorizationQueryModel = {
  __typename?: 'AuthorizationQueryModel';
  clientAuthorizations: ClientAuthorizations;
  legacyAuthorizations: LegacyAuthorizations;
};

export type BaApproveProjectChargesCommand = {
  projectCharges: Array<Scalars['Int']>;
};

export type BadDebtWriteOffCreatedResponse = {
  __typename?: 'BadDebtWriteOffCreatedResponse';
  badDebtWriteOffId?: Maybe<Scalars['Int']>;
  errors: Array<ValidationError>;
};

export type ChangeProductStatusCommand = {
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ChangeProjectChargesProjectCommand = {
  editNotes: Scalars['String'];
  newProjectId: Scalars['Int'];
  projectCharges: Array<Scalars['Int']>;
};

export type ChangeServiceDescriptionActiveInput = {
  displayName: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export enum ChargeType {
  Clerical = 'Clerical',
  Expense = 'Expense',
  Task = 'Task',
  Tax = 'Tax'
}

export type ClientAuthorizations = {
  __typename?: 'ClientAuthorizations';
  commands: Array<Maybe<CommandAuthorization>>;
  menus: Array<MenuAuthorizations>;
};

/** An exhaustive list of all command types that can be authorized by roles */
export enum CommandAuthorization {
  AddRollupInvoiceLineItemCommand = 'AddRollupInvoiceLineItemCommand',
  BillRollupInvoiceCommand = 'BillRollupInvoiceCommand',
  BulkAddRollupLineItemsCommand = 'BulkAddRollupLineItemsCommand',
  CancelRollupInvoiceCommand = 'CancelRollupInvoiceCommand',
  CreateProjectGroupCommand = 'CreateProjectGroupCommand',
  CreateRollupInvoiceCommand = 'CreateRollupInvoiceCommand',
  EditProjectGroupCommand = 'EditProjectGroupCommand',
  EditRollupInvoiceCommand = 'EditRollupInvoiceCommand',
  RemoveRollupInvoiceLineItemCommand = 'RemoveRollupInvoiceLineItemCommand'
}

export type CorpLocation = {
  __typename?: 'CorpLocation';
  companyName: Scalars['String'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CorpLocationQueryModel = {
  __typename?: 'CorpLocationQueryModel';
  all: Array<CorpLocation>;
};

export type CreateCustomerCommand = {
  address1: Scalars['String'];
  address2: Scalars['String'];
  adminNotes?: InputMaybe<Scalars['String']>;
  attn?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  electronicInvoicingSystem?: InputMaybe<ElectronicInvoicingSystem>;
  fax: Scalars['String'];
  name: Scalars['String'];
  nmTaxable: Scalars['Boolean'];
  notes: Scalars['String'];
  phone: Scalars['String'];
  state: Scalars['String'];
  subAccountOf?: InputMaybe<Scalars['String']>;
  txTaxable: Scalars['Boolean'];
  zip: Scalars['String'];
};

export type CreateCustomerResponse = {
  __typename?: 'CreateCustomerResponse';
  createCustomerRequestId: Scalars['UUID'];
  errors: Array<ValidationError>;
};

export type CreateInvoiceChargeCommand = {
  date: Scalars['LocalDate'];
  eeCode: Scalars['String'];
  employee?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['Int']>;
  glAccount?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  notes: Scalars['String'];
  projectNumber: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rateSheetName?: InputMaybe<Scalars['String']>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  type: ChargeType;
  unit?: InputMaybe<RateUnit>;
};

export type CreateProductCommand = {
  isVariableRate: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateProductResponse = {
  __typename?: 'CreateProductResponse';
  errors: Array<ValidationError>;
  productId?: Maybe<Scalars['Int']>;
};

export type CreateProjectChargeCommand = {
  date: Scalars['LocalDate'];
  eeCode: Scalars['String'];
  employee?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['Int']>;
  glAccount?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  notes: Scalars['String'];
  projectNumber: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rateSheetName?: InputMaybe<Scalars['String']>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  type: ChargeType;
  unit?: InputMaybe<RateUnit>;
};

export type CreateProjectChargeResponse = {
  __typename?: 'CreateProjectChargeResponse';
  errors: Array<ValidationError>;
  projectChargeId?: Maybe<Scalars['Int']>;
};

export type CustomerMutationModel = {
  __typename?: 'CustomerMutationModel';
  addBadDebtWriteOff: BadDebtWriteOffCreatedResponse;
  approveCustomer: ValidationResponse;
  createCustomer: CreateCustomerResponse;
  editCustomer: ValidationResponse;
  editElectronicInvoicing: ValidationResponse;
  payBadDebt: ValidationResponse;
  rejectCustomer: ValidationResponse;
};


export type CustomerMutationModelAddBadDebtWriteOffArgs = {
  input: AddBadDebtWriteOffCommand;
};


export type CustomerMutationModelApproveCustomerArgs = {
  input: ApproveCustomerCommand;
};


export type CustomerMutationModelCreateCustomerArgs = {
  input: CreateCustomerCommand;
};


export type CustomerMutationModelEditCustomerArgs = {
  input: EditCustomerCommand;
};


export type CustomerMutationModelEditElectronicInvoicingArgs = {
  input: EditElectronicInvoicingCommand;
};


export type CustomerMutationModelPayBadDebtArgs = {
  input: PayBadDebtCommand;
};


export type CustomerMutationModelRejectCustomerArgs = {
  input: RejectCustomerCommand;
};

export type EditCustomerCommand = {
  address1: Scalars['String'];
  address2: Scalars['String'];
  adminNotes?: InputMaybe<Scalars['String']>;
  attn?: InputMaybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  electronicInvoicingSystem?: InputMaybe<ElectronicInvoicingSystem>;
  fax: Scalars['String'];
  mainNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  nmTaxable: Scalars['Boolean'];
  notes: Scalars['String'];
  number: Scalars['String'];
  phone: Scalars['String'];
  state: Scalars['String'];
  subNumber?: InputMaybe<Scalars['String']>;
  txTaxable: Scalars['Boolean'];
  zip: Scalars['String'];
};

export type EditElectronicInvoicingCommand = {
  customerNumber: Scalars['String'];
  electronicInvoicingSystem?: InputMaybe<ElectronicInvoicingSystem>;
};

export type EditProjectChargeCommand = {
  billable: Scalars['Boolean'];
  date?: InputMaybe<Scalars['LocalDate']>;
  editNotes: Scalars['String'];
  eeCode: Scalars['String'];
  employee?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['Int']>;
  glAccount?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  label: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rateSheetName?: InputMaybe<Scalars['String']>;
  taxable?: InputMaybe<Scalars['Boolean']>;
  unit?: InputMaybe<RateUnit>;
};

export type EditProjectChargesEeCodeCommand = {
  editNotes: Scalars['String'];
  eeCode: Scalars['String'];
  projectCharges: Array<Scalars['Int']>;
};

export enum ElectronicInvoicingSystem {
  Actian = 'Actian',
  Ariba = 'Ariba',
  OpenInvoice = 'OpenInvoice',
  SmartGep = 'SmartGEP'
}

export type LegacyAuthorizations = {
  __typename?: 'LegacyAuthorizations';
  administerOverheadProjectCharges: Scalars['Boolean'];
  administerProjectCharges: Scalars['Boolean'];
  approveChargeAdjustments: Scalars['Boolean'];
  approveCharges: Scalars['Boolean'];
  approveCustomer: Scalars['Boolean'];
  approveThirdPartyInvoices: Scalars['Boolean'];
  assignRateSheet: Scalars['Boolean'];
  cancelInvoices: Scalars['Boolean'];
  changeProjectCustomer: Scalars['Boolean'];
  createProject: Scalars['Boolean'];
  createThirdPartyInvoices: Scalars['Boolean'];
  editCustomer: Scalars['Boolean'];
  editCustomerElectronicInvoicing: Scalars['Boolean'];
  editImportedExpenses: Scalars['Boolean'];
  editProject: Scalars['Boolean'];
  editProjectCharges: Scalars['Boolean'];
  impersonate: Scalars['Boolean'];
  isMobileEmployee: Scalars['Boolean'];
  manageBadDebtWriteOffs: Scalars['Boolean'];
  manageCorpLocations: Scalars['Boolean'];
  manageDivisions: Scalars['Boolean'];
  manageFieldOffices: Scalars['Boolean'];
  manageInvoices: Scalars['Boolean'];
  manageProducts: Scalars['Boolean'];
  manageRateSheets: Scalars['Boolean'];
  manageServiceDescriptions: Scalars['Boolean'];
  manageThirdPartyInvoiceConfig: Scalars['Boolean'];
  pmApprove: Scalars['Boolean'];
  psApprove: Scalars['Boolean'];
  requestCustomer: Scalars['Boolean'];
  requestProject: Scalars['Boolean'];
  reviewAssignedOfficeProjectRequests: Scalars['Boolean'];
  reviewProjectRequests: Scalars['Boolean'];
  reviewWorkData: Scalars['Boolean'];
  reviewWorkWeeks: Scalars['Boolean'];
  updateEmployeeFieldOffice: Scalars['Boolean'];
  updateFinalPayroll: Scalars['Boolean'];
  updateRoles: Scalars['Boolean'];
  usesWorkflowTimeEntry: Scalars['Boolean'];
  viewCustomer: Scalars['Boolean'];
  viewInvoices: Scalars['Boolean'];
  viewOfficeBilling: Scalars['Boolean'];
  viewOverheadCharges: Scalars['Boolean'];
  viewPayrollAdminData: Scalars['Boolean'];
  viewPerformanceStats: Scalars['Boolean'];
  viewProjectStatus: Scalars['Boolean'];
  viewRevenueReports: Scalars['Boolean'];
  viewWorkData: Scalars['Boolean'];
};

export type MarkProjectChargesBillableCommand = {
  billable: Scalars['Boolean'];
  editNotes: Scalars['String'];
  projectCharges: Array<Scalars['Int']>;
};

export type MarkProjectChargesTaxableCommand = {
  projectCharges: Array<Scalars['Int']>;
  taxable: Scalars['Boolean'];
};

export enum MenuAuthorizations {
  CorporateStructure = 'CorporateStructure',
  CreateNewProject = 'CreateNewProject',
  Customers = 'Customers',
  EeCodes = 'EeCodes',
  EmployeeRoles = 'EmployeeRoles',
  Employees = 'Employees',
  Expenses = 'Expenses',
  FieldOffices = 'FieldOffices',
  Impersonate = 'Impersonate',
  Invoices = 'Invoices',
  None = 'None',
  OfficeBillingReport = 'OfficeBillingReport',
  PendingCustomers = 'PendingCustomers',
  PerformanceStats = 'PerformanceStats',
  Products = 'Products',
  ProjectCharges = 'ProjectCharges',
  ProjectRequests = 'ProjectRequests',
  Projects = 'Projects',
  Rates = 'Rates',
  RequestNewProject = 'RequestNewProject',
  RevenueReports = 'RevenueReports',
  SalesTaxReport = 'SalesTaxReport',
  ThirdPartyInvoices = 'ThirdPartyInvoices',
  TimesheetReview = 'TimesheetReview',
  UnbilledProjectCharges = 'UnbilledProjectCharges',
  ViewProjectStatus = 'ViewProjectStatus'
}

export type MutationModel = {
  __typename?: 'MutationModel';
  customers: CustomerMutationModel;
  products: ProductsMutationModel;
  projectCharges: ProjectChargesMutationModel;
  serviceDescriptions: ServiceDescriptionMutationModel;
};

export type PayBadDebtCommand = {
  amount: Scalars['Decimal'];
  badDebtWriteOffId: Scalars['Int'];
  customerNumber: Scalars['String'];
  date: Scalars['LocalDate'];
};

export type PmApproveProjectChargesCommand = {
  projectCharges: Array<Scalars['Int']>;
};

export type Product = {
  __typename?: 'Product';
  isActive: Scalars['Boolean'];
  isVariableRate: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ProductsMutationModel = {
  __typename?: 'ProductsMutationModel';
  changeStatus: ValidationResponse;
  create: CreateProductResponse;
};


export type ProductsMutationModelChangeStatusArgs = {
  input: ChangeProductStatusCommand;
};


export type ProductsMutationModelCreateArgs = {
  input: CreateProductCommand;
};

export type ProductsQueryModel = {
  __typename?: 'ProductsQueryModel';
  all: Array<Product>;
};

export type ProjectChargesMutationModel = {
  __typename?: 'ProjectChargesMutationModel';
  baApprove: ValidationResponse;
  changeProject: ValidationResponse;
  createInvoiceCharge: CreateProjectChargeResponse;
  createProjectCharge: CreateProjectChargeResponse;
  editEeCode: ValidationResponse;
  editProjectCharge: ValidationResponse;
  markBillable: ValidationResponse;
  markTaxable: ValidationResponse;
  pmApprove: ValidationResponse;
  psApprove: ValidationResponse;
  splitCharge: SplitChargeResponse;
};


export type ProjectChargesMutationModelBaApproveArgs = {
  input: BaApproveProjectChargesCommand;
};


export type ProjectChargesMutationModelChangeProjectArgs = {
  input: ChangeProjectChargesProjectCommand;
};


export type ProjectChargesMutationModelCreateInvoiceChargeArgs = {
  input: CreateInvoiceChargeCommand;
};


export type ProjectChargesMutationModelCreateProjectChargeArgs = {
  input: CreateProjectChargeCommand;
};


export type ProjectChargesMutationModelEditEeCodeArgs = {
  input: EditProjectChargesEeCodeCommand;
};


export type ProjectChargesMutationModelEditProjectChargeArgs = {
  input: EditProjectChargeCommand;
};


export type ProjectChargesMutationModelMarkBillableArgs = {
  input: MarkProjectChargesBillableCommand;
};


export type ProjectChargesMutationModelMarkTaxableArgs = {
  input: MarkProjectChargesTaxableCommand;
};


export type ProjectChargesMutationModelPmApproveArgs = {
  input: PmApproveProjectChargesCommand;
};


export type ProjectChargesMutationModelPsApproveArgs = {
  input: PsApproveProjectChargesCommand;
};


export type ProjectChargesMutationModelSplitChargeArgs = {
  input: SplitChargeCommand;
};

export type PsApproveProjectChargesCommand = {
  projectCharges: Array<Scalars['Int']>;
};

export type QueryModel = {
  __typename?: 'QueryModel';
  authorization: AuthorizationQueryModel;
  corpLocations: CorpLocationQueryModel;
  products: ProductsQueryModel;
  serviceDescriptions: ServiceDescriptionQueryModel;
};

export enum RateUnit {
  Day = 'Day',
  Each = 'Each',
  Foot = 'Foot',
  Gallon = 'Gallon',
  Hourly = 'Hourly',
  Mile = 'Mile'
}

export type RejectCustomerCommand = {
  createCustomerRequestId: Scalars['String'];
  reason: Scalars['String'];
};

export type ServiceDescription = {
  __typename?: 'ServiceDescription';
  description: Scalars['String'];
  displayName: Scalars['String'];
  industry: Scalars['String'];
  isActive: Scalars['Boolean'];
  isOverhead: Scalars['Boolean'];
  legacyWorkCode: Scalars['String'];
  projectType: Scalars['String'];
};

export type ServiceDescriptionMutationModel = {
  __typename?: 'ServiceDescriptionMutationModel';
  changeIsActive: ValidationResponse;
  update: ValidationResponse;
};


export type ServiceDescriptionMutationModelChangeIsActiveArgs = {
  input: ChangeServiceDescriptionActiveInput;
};


export type ServiceDescriptionMutationModelUpdateArgs = {
  input: UpdateServiceDescriptionInput;
};

export type ServiceDescriptionQueryModel = {
  __typename?: 'ServiceDescriptionQueryModel';
  all: Array<ServiceDescription>;
};

export type SplitChargeArgsInput = {
  label: Scalars['String'];
  notes: Scalars['String'];
  projectNumber: Scalars['Int'];
  quantity: Scalars['Decimal'];
  rate: Scalars['Decimal'];
  rateSheetName: Scalars['String'];
  taxable: Scalars['Boolean'];
};

export type SplitChargeCommand = {
  adminNotes: Scalars['String'];
  id: Scalars['Int'];
  label: Scalars['String'];
  notes: Scalars['String'];
  projectId?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['Decimal'];
  splitChargeArgs: Array<SplitChargeArgsInput>;
  taxable: Scalars['Boolean'];
};

export type SplitChargeResponse = {
  __typename?: 'SplitChargeResponse';
  errors: Array<ValidationError>;
  splitChargeIds: Array<Scalars['Int']>;
};

export type UpdateServiceDescriptionInput = {
  description: Scalars['String'];
  industry: Scalars['String'];
  legacyWorkCode: Scalars['String'];
  previousDisplayName?: InputMaybe<Scalars['String']>;
  projectType: Scalars['String'];
};

export type ValidationError = {
  __typename?: 'ValidationError';
  argumentName?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type ValidationResponse = {
  __typename?: 'ValidationResponse';
  errors: Array<ValidationError>;
};
